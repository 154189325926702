import React from "react";

import CenahPage from "@components/2-molecules/CenahPage";

const IndexPage = () => (
  <main>
    <CenahPage />
  </main>
);

export default IndexPage;
