import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./Button.module.scss";

const Button = ({ children, onClick, href, className, isExternal }) => {
  const Component = href ? "a" : "button";

  return (
    <Component
      className={classNames(classes.root, className)}
      onClick={onClick}
      href={href}
      {...(isExternal && {
        rel: "noopener noreferrer nofollow",
      })}
    >
      {children}
    </Component>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  className: PropTypes.string,
  isExternal: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  onClick: null,
  href: null,
  className: null,
  isExternal: false,
};

export default Button;
