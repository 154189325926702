import React, { useState } from "react";

import Button from "@components/1-atoms/Button";
import card1 from "@assets/docs/fi.pdf";
import card2 from "@assets/docs/longwood.pdf";

import * as classes from "./CenahPage.module.scss";

const CenahPage = () => {
  const PASSWORD = "zellie";
  const [passwordInput, setPasswordInput] = useState("");

  return (
    <div className={classes.root}>
      {passwordInput === PASSWORD ? (
        <>
          <Button className={classes.button} href={card1}>
            Click me!!
          </Button>
          <Button className={classes.button} href={card2}>
            And then Click me!!
          </Button>
        </>
      ) : (
        <input
          aria-label="Password"
          type="password"
          value={passwordInput}
          onChange={(event) => setPasswordInput(event.target.value)}
        />
      )}
    </div>
  );
};

export default CenahPage;
